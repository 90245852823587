import React, { memo } from 'react';
import Loading, { LoadingSpinner, LoadingText } from './loading';
import { getResourceText } from '../lib/language';

const PageLoading = memo(() =>
  <div className="page-loading-container">
    <Loading>
      <LoadingSpinner/>
      <LoadingText>{getResourceText('Loading')}</LoadingText>
    </Loading>
  </div>
);

export default PageLoading;