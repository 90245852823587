import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useAdminUser } from '../../../hooks/adminUser';
import ErrorHeading from '../../../components/errorHeading';
import PageLoading from '../../../components/pageLoading';
import AdminPage from '../../../components/adminPage';
import Icon, { GlyphNames } from '../../../components/icon';
import { getResourceText } from '../../../lib/language';
import { useApi } from '../../../hooks/api';
import { sortResourcesByName } from '../../../lib/sort';

const AdminResourcePacks = () => {
  useAdminUser();
  const { admin: { getResourcePacks } } = useApi();

  const [resourcePacks, setResourcePacks] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    // get resource packs allowed for user
    const getData = async () => {
      const { status, json: resources } = await getResourcePacks();

      if (status === 200) {
        setResourcePacks(sortResourcesByName(resources));
      } else {
        setError(true);
        // need to handle this below
      }
    };

    if (!resourcePacks && getResourcePacks) {
      getData().catch(() => setError(true));
    }

  }, [resourcePacks, getResourcePacks]);

  return (
    <AdminPage withBack={false}>
      <div className="admin-resource-packs-container">
        <h1 className="admin-resource-packs-container__main-heading">{getResourceText('resourcePacks')}</h1>
        {!error && !resourcePacks ? <PageLoading/> : null}
        {error ? <ErrorHeading text="Error getting resource packs"/> : null}
        {resourcePacks && !resourcePacks.length ?
          <h3
            className="admin-resource-packs-container__sub-heading">{getResourceText('noResourcePacksFound')}</h3> : null}
        <div className="admin-resource-packs-links">
          {resourcePacks ? resourcePacks.map(({ resourceName, resourceId }) =>
            <Link key={resourceId} to={`/admin/resource-packs/${resourceId}/edit`}
                  className="admin-resource-packs-link">{resourceName}</Link>) : null}
          <Link
            to="/admin/resource-packs/create"
            aria-label={getResourceText('addNewResourcePack')}
            className="admin-resource-packs-link"
            title={getResourceText('addNewResourcePack')}>
            <Icon glyphName={GlyphNames.plus} css="admin-resource-packs-link--add-new"/>
          </Link>
        </div>
      </div>
    </AdminPage>
  );

};

export default AdminResourcePacks;